<template>
    <div class="container-fluid main-color">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">{{ currentRouteName === 'hrd-behavior-question-create'? 'Add Question' : 'Edit Question' }}</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <place-holder v-if="loading"></place-holder>
                <div class="card">
                    <div v-if="!loading" class="card-body">
                        <form class="parsley-examples">
                            <div class="row mb-3">
                                <label for="name" class="col-md-2 ms-4 form-label">
                                    Questions
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                    <input type="text" id="name" name="name" class="form-control" placeholder="Question"
                                        v-model="behavior.name" :class="{
                                            'p-invalid':
                                                v$.behavior.name.$error || errorFor('name'),
                                        }" />
                                    <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                        errors: v$.behavior.name.$errors,
                                        value: 'Question',
                                    }"></v-errors>
                                </div>

                                <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                    <input type="text" id="name" name="name" class="form-control"
                                        v-model="behavior.name" disabled/>
                                </div>

                            </div>

                            <div class="row mb-3">
                                <label for="level" class="col-md-2 ms-4 form-label">
                                    Evaluating Criteria
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                    <v-select class="custom-selectCSS" v-model="behavior.evaluating_criteria_id"
                                        label="name" :options="evaluatingCriteria" :clearable="false"
                                        :selectable="(options) => isSelectableCriteria(options)"
                                        :reduce="(name) => name.id" :class="{
                                            'p-invalid':
                                                v$.behavior.evaluating_criteria_id.$error || errorFor('evaluating_criteria_id'),
                                        }">
                                    </v-select>
                                    <v-errors :serverErrors="errorFor('evaluating_criteria_id')" :vuelidateErrors="{
                                        errors: v$.behavior.evaluating_criteria_id.$errors,
                                        value: 'Evaluating Criteria',
                                    }"></v-errors>
                                </div>

                                 <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                    <v-select class="custom-selectCSS" v-model="behavior.evaluating_criteria_id"
                                        label="name" :options="evaluatingCriteria" :clearable="false"
                                        disabled >
                                    </v-select>
                                    
                                </div>

                            </div>

                            <div class="float-end me-5" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                <button v-show="!loading" type="button" @click="addAnswer()"
                                    class="btn btn-sm btn-blue waves-effect waves-light mb-2">
                                    Add Answer
                                </button>
                            </div>

                            <div class="row mt-5 text-center">
                                <div class="d-flex">
                                    <div class="col-md-5">
                                        <label for="name" class="form-label">
                                            Answer
                                            <span class="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-5">
                                        <label for="name" class="form-label">
                                            Score
                                            <span class="text-danger">*</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-4" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                <div v-for="(item, index) in behavior.answers" :key="index" class="d-flex m-2">
                                    <div class="col-md-4 me-5 ms-4">
                                        <input type="text" id="name" name="name" class="form-control" placeholder="Answer"
                                            v-model="item.name" />
                                        <!-- :class="{
                                                'p-invalid':
                                                    v$.item.name.$error || errorFor('name'),
                                            }" -->
                                        <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                            errors: v$.item.name.$errors,
                                            value: 'Answer',
                                        }"></v-errors> -->
                                    </div>
                                    <div class="col-md-4 me-4">
                                        <input type="number" id="score" name="score" class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 && Math.abs(this.value) <=5 ? Math.abs(this.value) : null"
                                            placeholder="Score" v-model="item.score" />
                                        <!-- :class="{
                                                'p-invalid':
                                                    v$.item.score.$error || errorFor('name'),
                                            }" -->
                                        <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                            errors: v$.item.score.$errors,
                                            value: 'Score',
                                        }"></v-errors> -->
                                    </div>
                                    <div class="col-md-2" v-show="index != 0">
                                        <button v-show="!loading" type="button" @click="removeAnswer(index)"
                                            class="btn btn-sm btn-danger waves-effect waves-light mb-2">
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>


                               <div class="row mb-4" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                <div v-for="(item, index) in behavior.answers" :key="index" class="d-flex m-2">
                                    <div class="col-md-4 me-5 ms-4">
                                        <input type="text" id="name" name="name" class="form-control" placeholder="Answer"
                                            v-model="item.name" disabled/>
                                        <!-- :class="{
                                                'p-invalid':
                                                    v$.item.name.$error || errorFor('name'),
                                            }" -->
                                        <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                            errors: v$.item.name.$errors,
                                            value: 'Answer',
                                        }"></v-errors> -->
                                    </div>
                                    <div class="col-md-4 me-4">
                                        <input type="number" id="score" name="score" class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 && Math.abs(this.value) <=5 ? Math.abs(this.value) : null"
                                            placeholder="Score" v-model="item.score" disabled/>
                                        <!-- :class="{
                                                'p-invalid':
                                                    v$.item.score.$error || errorFor('name'),
                                            }" -->
                                        <!-- <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                                            errors: v$.item.score.$errors,
                                            value: 'Score',
                                        }"></v-errors> -->
                                    </div>
                                    <div class="col-md-2" v-show="index != 0" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                        <button v-show="!loading" type="button" @click="removeAnswer(index)"
                                            class="btn btn-sm btn-danger waves-effect waves-light mb-2">
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3" v-if="currentRouteName === 'hrd-behavior-question-update'">
                                <label for="status" class="col-md-2 ms-4 form-label">
                                    Status
                                </label>
                                <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                    <select name="status" v-model="status" class="form-control">
                                        <option :value="1">Active</option>
                                        <option :value="0">Inactive</option>
                                    </select>
                                </div>

                                <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                    <select name="status" v-model="status" class="form-control" disabled>
                                        <option :value="1">Active</option>
                                        <option :value="0">Inactive</option>
                                    </select>
                                </div>

                            </div>

                            <div class="row mb-3" v-if="status == 0">
                                <label for="remark" class="col-md-2 ms-4 form-label">
                                    Remark
                                </label>
                                <div class="col-md-6" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                    <textarea type="text" id="remark" name="remark" class="form-control"
                                        placeholder="Remark" v-model="remark" :class="{
                                            'p-invalid':
                                                v$.remark.$error || errorFor('remark'),
                                        }">
                                    </textarea>
                                    <v-errors :serverErrors="errorFor('remark')" :vuelidateErrors="{
                                        errors: v$.remark.$errors,
                                        value: 'Remark',
                                    }"></v-errors>
                                </div>

                                <div class="col-md-6" v-else-if="hasPermissions(['hrd-behaviour-question-criteria-list'])">
                                    <textarea type="text" id="remark" name="remark" class="form-control"
                                        placeholder="Remark" v-model="remark" disabled >
                                </textarea>
                                    
                                </div>

                            </div>
                            <!-- end row -->
                            <div class="row" v-if="hasPermissions(['hrd-behaviour-question-criteria-edit', 'hrd-behaviour-question-criteria-create'])">
                                <div class="col-md-12">
                                    <div class="text-center mt-3 mb-3">
                                        <router-link :to="{ name: 'hrd-behavior-question' }">
                                            <button type="button" class="btn w-sm btn-secondary me-5">
                                                Cancel
                                            </button>
                                        </router-link>
                                        <button :disabled="isLoading ? true : false" type="button"
                                            class="btn w-sm btn-success waves-effect waves-light px-3" @click="
                                                currentRouteName === 'hrd-behavior-question-update'
                                                    ? updateBehaviorQuestion()
                                                    : createBehaviorQuestion()
                                                ">
                                            <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            {{ isLoading == true ? 'Loading...' : 'Save' }}
                                        </button>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
    setup() {
        const toast = useToast();
        return { v$: useVuelidate(), toast };
    },
    components: {
        vSelect,
    },
    mixins: [validationErrors, userHasPermissions],
    data() {
        return {
            behavior: {
                name: "",
                evaluating_criteria_id: "",
                answers: [
                    {
                        name: "",
                        score: ""
                    }
                ]
            },
            evaluatingCriteria: [],
            status: "1",
            remark: "",
            baseUrl: process.env.VUE_APP_BASE_URL,
            baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
            loading: false,
            isLoading: false
        };
    },
    validations() {
        return {
            behavior: {
                name: { required },
                evaluating_criteria_id: { required },
                // answers: [
                //     {
                //         name: { required },
                //         score: { required },
                //     }
                // ]
            },
            remark: {
                required: requiredIf(() => {
                    return this.status != 1;
                })
            },
        };
    },
    methods: {
        isSelectableCriteria(options) {
            if(!this.behavior.evaluating_criteria_id || !options) return true;
            return this.behavior.evaluating_criteria_id != options.id;
        },
        async getAllEvaluatingCriteria() {
            this.loading = true;
            this.$Progress.start();
            await axios.post(`${this.baseUrl}admin/v2/get-evaluating-criteria`).then((response) => {
                // this.evaluatingCriteria = response.data.data;
                this.evaluatingCriteria = Object.values(response.data.data).filter(function (val) {
                    //for active Evaluating Criteria
                    if (val.status == 1 && val.type == 'Orientation') {
                    return {
                        id: val.id,
                        level: val.level,
                        name: val.name,
                        status: val.status,
                        type: val.type,
                    };
                    }
                });
                this.loading = false;
                this.$Progress.finish();
            });
        },
        addAnswer() {
            if (this.behavior.answers.length == 5) {
                this.toast.error("Maximum Answer Limit is 5 !");
                return
            } else {
                this.behavior.answers.push({
                    name: "",
                    score: ""
                })
            }
        },
        removeAnswer(index) {
            this.behavior.answers.splice(index, 1);
        },
        async getBehaviorQuestionById() {
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v2/hrd-behaviour-question/${this.$route.params.id}`)
                .then((response) => {
                    const result = response.data.data;
                    this.behavior = result;
                    this.behavior.name = result.name;
                    this.behavior.evaluating_criteria_id = result.evaluating_criteria_id;
                    this.behavior.answers = result.hrd_behaviour_answers.map((item) => {
                        return {
                            name: item.name,
                            score: item.score
                        };
                    });
                    this.status = result.status;
                    this.remark = result.remark;
                })
                .catch(() => {
                    this.toast.error("Not Found Criteria!");
                });
            this.loading = false;
        },

        checkEmptyValue(array) {
            let answerAndScore = Object.values(array);
            if (answerAndScore.includes(null) || answerAndScore.includes("")) {
                return false;
            } else {
                return true;
            }
        },

        async createBehaviorQuestion() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) return;

            let checkAnswer = [];
            this.behavior.answers.forEach((array) => {
                let answer = this.checkEmptyValue(array);
                checkAnswer.push(answer);
            });
            if (checkAnswer.includes(false)) {
                this.toast.error("Please Fill All Input!");
                return;
            }

            this.isLoading = true;

            await axios
                .post(`${this.baseUrl}admin/v2/hrd-behaviour-question`, this.behavior)
                .then(() => {
                    this.$router.push({ name: "hrd-behavior-question" });
                    this.toast.success("Successfully Created Behavior Question!");
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errors = error.response;
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },

        async updateBehaviorQuestion() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) return;

            let checkAnswer = [];
            this.behavior.answers.forEach((array) => {
                let answer = this.checkEmptyValue(array);
                checkAnswer.push(answer);
            });
            if (checkAnswer.includes(false)) {
                this.toast.error("Please Fill All Input!");
                return;
            }

            if(this.status == 1) this.remark = '';
            let data = {
                name: this.behavior.name,
                evaluating_criteria_id: this.behavior.evaluating_criteria_id,
                answers: this.behavior.answers,
                status: this.status,
                remark: this.remark
            }
            this.isLoading = true;
            axios
                .put(
                    `${this.baseUrl}admin/v2/hrd-behaviour-question/${this.$route.params.id}`,
                    data
                )
                .then(() => {
                    this.$router.push({ name: "hrd-behavior-question" });
                    this.toast.success("Successfully Behavior Question Updated!");
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },
    },
    created() {
        this.getAllEvaluatingCriteria();
        if (this.currentRouteName == "hrd-behavior-question-update") {
            this.getBehaviorQuestionById();
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>
  
<style>
.custom-selectCSS {
    --vs-dropdown-min-width: 160px;
    --vs-dropdown-max-height: 200px;
}
</style>
  